import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  logoImage = '/assets/images/logo_b.png';

  constructor() { }

  ngOnInit(): void {
  }

}
