<header class="uk-flex">
    <a href="#top" title="top">top</a> /
    <a href="/about" title="about">about</a>/
    <a href="/access" title="access">access</a>/
    <a href="/contact" title="contact">contact</a>/
    <a href="/shop" title="product">product</a>
    <a href="https://www.facebook.com/Shirakami.Life.Design/" title="facebook" target=”_blank”><img src="/assets/images/facebook.png"></a>
    <a href="https://www.instagram.com/shirakami_life_design/" title="instagram" target=”_blank”><img src="/assets/images/instagram.png"></a>
</header>
<div class="sns_icon uk-flex">
    <a href="https://www.facebook.com/Shirakami.Life.Design/" title="facebook" target=”_blank”><img src="/assets/images/facebook.png"></a>
    <a href="https://www.instagram.com/shirakami_life_design/" title="instagram" target=”_blank”><img src="/assets/images/instagram.png"></a>
</div>