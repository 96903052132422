import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Contact } from '../../models/contact';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.required,
    ]),
    phone: new FormControl('', [
    ]),
    address: new FormControl('', [
    ]),
    body: new FormControl('', [
      Validators.required,
    ]),
  });

  status = 'input';
  message = '';
  loading = false;

  constructor(
    private contactService: ContactService
  ) {
  }

  ngOnInit(): void {
  }

  async clickStatus() {
      this.loading = true;
      this.message = '';
      const contact: Contact = this.formGroup.value;

      try {
        const response = await this.contactService.postContact(contact);

        this.message = '送信しました';
        console.log('start postContact', response);
        this.status = status;
      } catch (e) {
        this.message = '送信に失敗しました。後ほどお試し下さい。';
      } finally {
        this.loading = false;
      }
  }

  public get name() {
    return this.formGroup.get('name');
  }

  public get email() {
    return this.formGroup.get('email');
  }

  public get phone() {
    return this.formGroup.get('phone');
  }

  public get address() {
    return this.formGroup.get('address');
  }

  public get body() {
    return this.formGroup.get('body');
  }
}
