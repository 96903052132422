<section id="top" class="wrap">

    <div class="container main full">
        <div class="sns_icon">
            <a href="https://www.facebook.com/Shirakami.Life.Design/" title="facebook" target=”_blank”><img src="/assets/images/top_facebook.png"></a>
            <a href="https://www.instagram.com/shirakami_life_design/" title="instagram" target=”_blank”><img src="/assets/images/top_instagram.png"></a>
        </div>
        <img class="logo" [src]="logoImage" alt="シラカミライフデザイン">
        <nav class="main__nav">
            <a class="main__nav__link" href="/about">about</a>
            <span>/</span>
            <a class="main__nav__link" href="/access">access</a>
            <span>/</span>
            <a class="main__nav__link" href="/contact">contact</a>
        </nav>

        <div class="shop-button">
            <a routerLink="/shop" title="web shop">product</a>
        </div>

        <footer class="uk-flex uk-flex-center">
            <div class="copyright">
                <a routerLink="/" fragment="top" title="トップ">@2021 SHIRAKAMI LIFE DESIGN</a>
            </div>
            <div class="policy">
                <a routerLink="/policy" fragment="top" title="プライバシーポリシー">プライバシーポリシー</a>
            </div>
        </footer>

    </div>

    <div class="overlay"></div>

</section>