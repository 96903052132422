<header class="header">
    <div class="container flex flex__center flex__center-h">
        <a href="/" title="トップ">
            <img class="logo" src="/assets/images/logo.svg" alt="シラカミライフデザイン">
            <h1 class="none">シラカミライフデザイン</h1>
        </a>

        <div id="nav-toggle" class="show-s">
            <div>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div id="gloval-nav">
            <nav>
                <ul>
                    <li><img src="/assets/images/logo_b.png" alt="シラカミライフデザイン"></li>
                    <li>
                        <a routerLink="/shop" title="top" class="header__nav__button">top</a>
                    </li>
                    <li>
                        <a routerLink="/shop/about" title="about" class="header__nav__button">about</a>
                    </li>
                    <li>
                        <a routerLink="/shop/category" title="category" class="header__nav__button">category</a>
                    </li>
                    <li>
                        <a routerLink="/" title="website" class="header__nav__button">website</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>

<nav class="nav flex color-blue hidden-s">
    <ul class="header__nav">
        <li class="header__nav__single">
            <a routerLink="/shop" title="top" class="header__nav__button">top</a>
        </li>
        <li class="header__nav__single">
            <a routerLink="/shop/about" title="about" class="header__nav__button">about</a>
        </li>
        <li class="header__nav__single">
            <a routerLink="/shop/category" title="category" class="header__nav__button">category</a>
        </li>
        <li class="header__nav__single">
            <a routerLink="/" title="website" class="header__nav__button">website</a>
        </li>
    </ul>
</nav>