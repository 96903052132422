import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './components/index/index.component';
import { AboutComponent } from './components/about/about.component';
import { AccessComponent } from './components/access/access.component';
import { ContactComponent } from './components/contact/contact.component';
import { PolicyComponent } from './components/policy/policy.component';
import { WebHeaderComponent } from './components/header/web-header.component';
import { WebFooterComponent } from './components/footer/web-footer.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'access',
    component: AccessComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'policy',
    component: PolicyComponent
  },
  {
    path: 'web-header',
    component: WebHeaderComponent
  },
  {
    path: 'web-footer',
    component: WebFooterComponent
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(mod => mod.ShopModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
