<footer class="footer wrap">
    <div class="container">
        <div class="basis-100 logo">
            <img class="logo__img" src="/assets/images/logo.svg" alt="シラカミライフデザイン">
        </div>
    </div>

    <div class="container flex flex__between flex__end flex__wrap">
        <div class="footer__info basis-100-s">
            <h2>シラカミ　ライフ　デザイン</h2>
            <p>
                〒037-0063 青森県五所川原市大町505-3 1-1<br> Tel/Fax : 0173 67 4629
            </p>
        </div>

        <div class="footer__link grow-1 basis-100-s">
            <a routerLink="/shop/policy" title="プライバシーポリシー">プライバシーポリシー</a>
            <a routerLink="/shop/law" title="特定商取引法に基づく表記">特定商取引法に基づく表記</a>
        </div>

        <div class="basis-100-s footer__copyright text-center">
            <a class="" href="/shop">©2021 SHIRAKAMI LIFE DESIGN</a>
        </div>
    </div>
</footer>