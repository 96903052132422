import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './components/index/index.component';
import { AboutComponent } from './components/about/about.component';
import { AccessComponent } from './components/access/access.component';
import { ContactComponent } from './components/contact/contact.component';
import { PolicyComponent } from './components/policy/policy.component';
import { WebHeaderComponent } from './components/header/web-header.component';
import { WebFooterComponent } from './components/footer/web-footer.component';
import { HeaderComponent } from './shop/components/header/header.component';
import { FooterComponent } from './shop/components/footer/footer.component';



@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AboutComponent,
    AccessComponent,
    ContactComponent,
    PolicyComponent,
    WebHeaderComponent,
    WebFooterComponent,
    HeaderComponent,
    FooterComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
