<section class="contact_container">
    <app-web-header></app-web-header>

    <div class="page__caption">
        <span>contact</span>
    </div>


    <div class="form__wrap">
        <img src="/assets/images/logo.svg" class="img_logo" alt="シラカミライフデザイン">
        <form [formGroup]=formGroup>
            <section class="left">
                <div class="form-group uk-flex uk-flex-center">
                    <label>name*</label>
                    <div>
                        <input type="text" formControlName="name" [className]="(name.dirty || name.touched) && name.hasError('required') ? 'error' : ''" value="" required>
                        <div class="error" *ngIf="(name.dirty || name.touched) && name.hasError('required')">
                            *必須項目です
                        </div>
                    </div>
                </div>

                <div class="form-group uk-flex uk-flex-center">
                    <label>mail*</label>
                    <div>
                        <input type="email" formControlName="email" [className]="(email.dirty || email.touched) && email.hasError('required') ? 'error' : ''" value="" required>
                        <div class="error" *ngIf="(email.dirty || email.touched) && email.hasError('required')">
                            *必須項目です
                        </div>
                    </div>
                </div>

                <div class="form-group uk-flex uk-flex-center">
                    <label>phone</label>
                    <div>
                        <input type="tel" formControlName="phone" value="">
                    </div>
                </div>

                <div class="form-group uk-flex uk-flex-center">
                    <label>address</label>
                    <div>
                        <input type="address" formControlName="address" value="">
                    </div>
                </div>
            </section>

            <section class="right">
                <div class="form-group flex-align-top textarea">
                    <label>Message</label>
                    <div class="flex-textarea">
                        <textarea formControlName="body" [className]="(body.dirty || body.touched) && body.hasError('required') ? 'error' : ''" required>
                </textarea>
                        <div class="error" *ngIf="(body.dirty || body.touched) && body.hasError('required')">
                            *必須項目です
                        </div>
                    </div>
                </div>
            </section>

            <div class="action text-right uk-margin-top">
                <button class="button primary bottom" (click)="clickStatus()" [disabled]="!formGroup.valid">
            送信
        </button>
            </div>
        </form>
    </div>

</section>
<app-web-footer></app-web-footer>