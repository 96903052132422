import { Injectable } from '@angular/core';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor() { }

  postContact(contact: Contact): Promise<any> {
    console.log('-- contact --', contact);

    const params = {
      body: contact
    };
    // return API.post('apijokendenkicontact', '/contacts', params);
    return new Promise<any>((resolve, reject) => {
      resolve(params);
    });
  }
}
