<section id="access" class="access_container">
    <app-web-header></app-web-header>

    <div class="page__caption">
        <span>access</span>
    </div>

    <div class="access_flex uk-flex uk-flex-center">
        <div class="access__left">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377.4752559130887!2d140.44426061947567!3d40.81034356080936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ba5335ace3f8f%3A0xe39f290bbe32f81b!2z44CSMDM3LTAwNjMg6Z2S5qOu55yM5LqU5omA5bed5Y6f5biC5aSn55S65aSn55S677yV77yQ77yV4oiS77yT!5e0!3m2!1sja!2sjp!4v1616398839074!5m2!1sja!2sjp"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="access__center">
            <img src="/assets/images/logo.svg" class="img_logo" alt="シラカミライフデザイン">
            <p>〒037-0063</p>
            <p>青森県 五所川原市 大町505-3 1-1</p>
            <p>tel : 0173 67 4629</p>
            <p>mail : info@shirakami-lifedesign.jp</p>
            <p>hp : https://shirakami-lifedesign.jp/</p>
        </div>
        <div class="access__right">
            <img src="/assets/images/signboard.jpg" class="img_signboard" alt="">
            <span>「立佞武多の館」より徒歩２分です</span>
        </div>
    </div>
</section>

<app-web-footer></app-web-footer>