import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  logoImage = '/assets/images/logo.png';
  menuAbout = '/assets/images/menu-about.png';
  menuAccess = '/assets/images/menu-access.png';
  menuContact = '/assets/images/menu-contact.png';

  constructor() { }

  ngOnInit(): void {
  }

}
