<section id="top" class="container">
    <a routerLink="/" title="top">
        <img class="logo" [src]="logoImage" title="シラカミライフデザイン">
    </a>
</section>

<section class="container">
    <div class="privacypolicy">

        <h2>プライバシーポリシー</h2>
        <hr>

        <h3 class="">個人情報保護方針</h3>
        <p class="margin-bottom-1">
            シラカミライフデザイン（以下、当社）では、個人情報の重要性を認識し、以下の基準に従って、適切な管理、保護に努めます。
        </p>

        <h3 class="">個人情報の収集、利用</h3>
        <p class="margin-bottom-1">
            当社では、お客様との取引、サービスの提供のために個人情報を適法に収集し、収集した目的の範囲内で、個人情報を利用いたします。
        </p>

        <h3 class="">個人情報の第三者への開示と提供</h3>
        <p class="margin-bottom-1">
            収集したお客様の個人情報は、法的な要請等によらない限り、お客様の承諾を得ない第三者に対して提供・開示はいたしません。
            業務の都合上、業務委託先に個人情報を提供する場合は、機密保持契約等によって業務委託先に個人情報保護を義務付けるとともに、業務委託先が適切に個人情報を取り扱うように管理いたします。
        </p>

        <h3 class="">個人情報の保護</h3>
        <p class="margin-bottom-1">
            当社では、個人情報の紛失、破壊、改ざん、不正アクセス、および漏えい等を防止するため、適切な対策を行います。
        </p>

        <h3 class="">法令および関連規範の遵守</h3>
        <p class="margin-bottom-1">
            当社は、個人情報の取り扱いに関して、個人情報保護法をはじめとする個人情報に関する法令および関連する規範を遵守します。
        </p>

        <h3 class="">個人情報の開示・訂正・削除</h3>
        <p class="margin-bottom-1">
            当社では、個人情報の開示・訂正・削除等の依頼があった場合、情報提供者本人であることを確認の上、すみやかに対応いたします。
        </p>

        <h3 class="">Google Analyticsの利用について</h3>
        <p class="margin-bottom-1">
            当サイトではWebサイトの利用状況を把握するためにGoogle Analyticsを利用しています。Google Analyticsから提供されるCookieを使用していますが、Google Analyticsによって個人を特定する情報は取得していません。
            Google Analyticsの利用により収集されたデータは、Google社のプライバシーポリシーに基づいて管理されています。Google Analyticsの利用規約・プライバシーポリシーについてはGoogle AnalyticsのWebサイトにてご確認ください。
            Google アナリティクス サービス利用規約
            Google ポリシーと規約

            法令や当社方針により、プライバシー・ポリシーを予告なく改訂することがあります。
        </p>
    </div>

    <div class="shop-button gray center">
        <a routerLink="/" fragment="">top</a>
    </div>

    <footer>
        <div class="copyright">
            <a routerLink="/" fragment="top" title="トップ">@2021 SHIRAKAMI LIFE DESIGN</a>
        </div>
        <div class="policy">
            <a routerLink="/policy" fragment="top" title="プライバシーポリシー">プライバシーポリシー</a>
        </div>
    </footer>
</section>
