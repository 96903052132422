<section id="about" class="about_container">
    <app-web-header></app-web-header>

    <div class="page__caption">
        <span>about</span>
    </div>

    <div class="uk-flex uk-flex-center">
        <div class="about__left">
            <span class="font_bold">SHIRAKAMI</span><span> LIFE DESIGNについて</span>
            <br>
            <span class="left_eng">/about SHIRAKAMI LIFE DESIGN</span>
        </div>
        <div class="about__right">
            <span class="font_bold">住まいづくりと暮らしの道具</span>
            <span class="right_eng font_bold">Housing design and Living tools</span>
            <p class="uk-margin-top">SHIRAKAMI LIFE DESIGNは
                <br>健康で安心して過ごせる住まいづくりと
                <br>その中で長く使える暮らしの道具を提案します
                <br>
                <br>自然素材にこだわった「住まい」
                <br>心とからだにやさしい「暮らしの道具」
                <br>
                <br>北国津軽の地から
                <br>日々の暮らしを　大切に思う人達へ
            </p>
            <span class="right_eng uk-margin-top">SHIRAKAMI LIFE DESIGN
                <br>Creating a Healthy and secure home
                <br>And we propose living tools that can be used for a long time
                <br>
                <br>" Housing design " that is parricular about natural materials
                <br>"Living tools " that are kind to the mind and body
                <br>
                <br>From Aomori Tsugaru
                <br>For those who think that everyday life is important
            </span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-large-top">
        <div class="about__left">
            <span>プロフィール</span>
            <span class="left_eng">/profile</span>
        </div>
        <div class="about__right">
            <p>ブランド名のSHIRAKAMIは、世界自然遺産「白神山地」の自然をモチーフにしています</p>
            <br>
            <p>豊かなブナの天然林を中心に、多種多様な動植物を育む白神山地
                <br>世界遺産の指定地域は立ち入りなどを規制され
                <br>自然の姿のままで後世へ引き継がれていきます
                <br>
                <br>一方でその周辺の里山と呼ばれる地域では、広葉樹林が高齢化し衰退が心配されています
                <br>里山の森林を守るためには、人の手による管理・育成が欠かせません
                <br>
                <br>この森林の育成と利活用を進め、地域の活性化を願ったことが契機となります
                <br>SHIRAKAMI LIFE DESIGNは、青森の地域資源や伝統文化を取り入れた
                <br>「現代の暮らしにより添う」ものづくり・ライフスタイルの提案を目財していきます
            </p>
            <span class="right_eng uk-margin-top">SHIRAKAMI of the brand name is the motif of the nature of the World Natural Heritage "Shirakami ".
                <br>
                <br>The Shirakami Mountains, which nurture a wide variety of animals and plants,
                <br>centered on the rich natural beech forest.
                <br>The designated areas of the World Heritage Site are restricted from entering,
                <br>and will be passed down to future generations in their natural form.
                <br>
                <br>On the other hand, in the surrounding area called Satoyama,
                <br>there are concerns about the again and decline of broad-leaved forests.
                <br>In order to protect the forests of Satoyama, manual management and cultivation are indispensable.
                <br>
                <br>This is to work on the cultivation and utilization of forests.
                <br>And it will be an opportunity to work on regional revitalization.
                <br>SHIRAKAMI LIFE DESIGN aims to propose manufacturing and lifestyles that "fit more with modern life "
                <br>that incorporate Aomori's local resources and traditional culture.
            </span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-large-top">
        <div class="about__left text-right">
            <span>名称</span>
            <span class="left_eng">name</span>
        </div>
        <div class="about__right">
            <p>SHIRAKAMI LIFE DESIGN
                <br>SHIRAKAMI LIFE DESIGN 建築設計事務所
            </p>
            <span class="right_eng">SHIRAKAMI LIFE DESIGN Architects</span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-top">
        <div class="about__left text-right">
            <span>事業内容</span>
            <span class="left_eng">service</span>
        </div>
        <div class="about__right">
            <p>住宅・店舗の設計、リノベーションデザイン
                <br>住環境コンサルティング
                <br>家具・インテリア小物類の企画設計・販売
            </p>
            <span class="right_eng">Housing / store design, renovation design
                <br>Living environment consulting
                <br>Plannig, design and sale of furniture and interior accessories
            </span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-top">
        <div class="about__left text-right">
            <span>登録等</span>
            <span class="left_eng">registration</span>
        </div>
        <div class="about__right">
            <p>一級建築士事務所 青森県知事登録 第1719号</p>
            <span class="right_eng">architect office registered by the governor of Aomori Prefecture No. 1719</span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-large-top">
        <div class="about__left">
            <span>コンセプト</span>
            <span class="left_eng">/Design concept</span>
        </div>
        <div class="about__right">
            <p class="font_bold">自然の摂理にかなったデザインであること</p>
            <p>太陽の光や風、植物の緑などの「自然の力」を享受し、
                <br>夏も冬も 過ごしやすく四季の移ろいを楽しめるデザイン
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    Design that fits the providence of nature
                </span> Enjoy the "natural power " of sunlight, wind, plants, and the changing of the four seasons
            </span>
            <p class="font_bold uk-margin-top">環境負荷の少ないものづくり</p>
            <p>手入れをすることにより長く使い続けることができるもの
                <br>地元の素材を使い、地域の技でかたちにする
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    Manufacturing with less environmental impact
                </span> Items that can be used for a long time by being cared for Use local materials and shape with local techniques
            </span>
            <p class="font_bold uk-margin-top">住環境と健康の関係に配慮すること</p>
            <p>化学物質や電磁波、騒音、微生物などによる
                <br>健康被害（過敏症やアレルギー）について理解し、配慮した設計・ものづくり
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    Considering the relationship between living environment and health
                </span> Design and manufacturing that understands and considers health hazards（hypersensitivity and allergies）
            <br>caused by chemical substances, electromagnetic waves, noise, microorganisms, etc.
            </span>
        </div>
    </div>
    <div class="uk-flex uk-flex-center uk-margin-large-top">
        <div class="about__left">
            <span>素材について</span>
            <span class="left_eng">/Concept of material selection</span>
        </div>
        <div class="about__right">
            <p class="font_bold">できるだけ最終的に土に還るもの、日本古来の自然な素材を選ぶ</p>
            <p>土や木、植物などから生まれたもの
                <br>青森ヒバ・杉・広葉樹、漆喰、蜜ロウ、渋柿、藍、和紙、麻布など
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    What will eventually return to the soil, a natural material from ancient Japan
                </span> Born from soil, trees, plants, etc.
            <br>Aomori Hiba, cedar and hardwood, plaster, beeswax, Kakishibu, indigo, Japanese paper, hemp, etc.
            </span>
            <p class="font_bold uk-margin-top">五感に心地よい素材を選ぶ</p>
            <p>見て触って心地よい自然のもの
                <br>時と共に味わいが深まり、愛着が増すもの
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    Choose a comfortable material to the senses
                </span> Natural things that are comfortable to see and touch
            <br>Things that deepen the taste and increase attachment over time
            </span>
            <p class="font_bold uk-margin-top">石油化学系の素材は必要最小限にする</p>
            <p>人や環境への影響や廃棄時の負荷などを考える
                <br>完成後に見えるものだけでなく、見えなくなる構造材・断熱材や接着剤などにも配慮する
            </p>
            <span class="right_eng">
                <span class="font_bold">
                    Minimize petrochemical materials
                </span> Impact on people and the environment, load at the time of disposal
            <br>Not only what you can see after completion, but also structural materials,
            <br>heat insulating materials, adhesives, etc. that disappear.
            </span>
        </div>
    </div>
</section>


<app-web-footer></app-web-footer>