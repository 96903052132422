import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'shirakami-lifedesign';

  currentModule = 'app';

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('router event', event);
        window.scrollTo(0, 0);

        const currentUrl = window.location.pathname;
        if (currentUrl.startsWith('/shop')) {
          this.currentModule = 'shop';
        } else {
          this.currentModule = 'app';
        }
      }
    });
  }
}
